import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 -14 300 276"
    fill={fill}
    {...rest}
  >
    <path
      d="M95 243A173 173 0 0 0 269 61c11-8 22-19 30-31-11 5-23 8-35 9 13-7 22-19 27-34-12 7-25 13-39 15a61 61 0 0 0-104 56C97 74 52 49 22 12a61 61 0 0 0 19 82c-10 0-20-3-28-8v1c0 30 21 54 49 60a61 61 0 0 1-28 1c8 24 31 42 58 42a123 123 0 0 1-91 26c27 17 59 27 94 27"
    />
  </svg>
)
