import { css } from 'styled-components'
import { rem, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { colors, fonts } = theme
const { primary, white, syntax } = colors

export default css`
  .gatsby-highlight {
    background: ${syntax.background};
    border-radius: ${rem(4)};
    color: ${syntax.default};
    direction: ltr;
    hyphens: none;
    overflow: auto;
    position: relative;
    tab-size: 4;
    text-align: left;
    text-shadow: 0 ${rem(1)} rgba(0, 0, 0, 0.15);
    white-space: pre;
    word-break: normal;
    word-spacing: normal;

    :before {
      ${position('absolute', 4, 4, 'auto', 'auto')}
      background: ${primary};
      border-radius: ${rem(16)};
      color: ${white};
      content: attr(data-language);
      font-family: ${fonts.primary};
      font-size: ${rem(12)};
      font-weight: 700;
      padding: 0 ${rem(8)};
    }

    pre {
      font-size: ${rem(14)};
      margin: 0;
      padding: ${rem(16)};
      white-space: pre-wrap;

      .token {
        color: ${syntax.token};
      }
      .comment {
        font-style: italic;
        color: ${syntax.comment};
      }
      .attr-name {
        color: ${syntax.attrName};
      }
      .attr-value {
        color: ${syntax.attrValue};
      }
      .boolean {
        color: ${syntax.boolean};
      }
      .class-name {
        color: ${syntax.className};
      }
      .constant {
        color: ${syntax.constant};
      }
      .function {
        color: ${syntax.function};
      }
      .hex {
        color: ${syntax.hex};
      }
      .keyword {
        color: ${syntax.keyword};
      }
      .number {
        color: ${syntax.number};
      }
      .operator {
        color: ${syntax.operator};
      }
      .parameter {
        color: ${syntax.parameter};
      }
      .property {
        color: ${syntax.property};
      }
      .punctuation {
        color: ${syntax.punctuation};
      }
      .regex {
        color: ${syntax.regex};
      }
      .selector {
        color: ${syntax.selector};
      }
      .string {
        color: ${syntax.string};
      }
    }
  }

  [data-language] pre {
    padding-top: ${rem(28)};
  }

  ${media.xsmall`
    .gatsby-highlight {
      pre {
        font-size: ${rem(16)};
      }
    }
  `}
`
