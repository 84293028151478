import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    height={`${size}px`}
    width={`${size}px`}
    viewBox="40 40 120 120"
    fill={fill}
    {...rest}
  >
    <path
      d="M90 68c1-2 3-2 4-1l46 41 2 2 1 3 5 13 2 6 4 13c1 2 0 3-2 2l-14-2-6-2-13-2-3-1-3-2-45-40c-2-1-2-3-1-5l23-25zm36 69l3 1h3l3-2 6-7 2-3-2-6-1-3-1-3-3 2-5 6a179 179 0 0 1-4 5l-5 6-3 3 7 1zM45 74l23-26h5l14 13v4L65 91h-5L46 79c-2-2-2-4-1-5z"
    />
  </svg>
)
