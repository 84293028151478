import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import projects from 'lib/projects'
import MenuItem from 'components/menu-item'

const Container = styled.ul`
  margin: 0;
  text-align: left;
  user-select: none;
`

export default ({ currentPath = '' }) => {
  return (
    <Container>
      {projects.map(({ name, path }) => (
        <MenuItem key={path}>
          {path === currentPath ? (
            <span>{name}</span>
          ) : (
            <Link to={path}>{name}</Link>
          )}
        </MenuItem>
      ))}
    </Container>
  )
}
