import React from 'react'
import theme from 'lib/styles/theme'

const baseWidth = 1740
const baseHeight = 1160
const ratio = baseWidth / baseHeight

export default ({
  height = 48,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    height={`${height}px`}
    width={`${Math.ceil(height * ratio)}px`}
    viewBox={`0 0 ${baseWidth} ${baseHeight}`}
    fill={fill}
    {...rest}
  >
    <path
      d="M293 1145 c-124 -27 -210 -95 -262 -204 -23 -50 -26 -68 -25 -161 0 -85 5 -116 23 -163 43 -108 139 -205 248 -248 77 -30 266 -34 355 -6 l57 17 -33 78 c-18 42 -43 107 -55 144 l-22 66 -45 -25 c-88 -50 -180 -27 -216 54 -17 39 -19 52 -9 84 23 76 96 108 175 75 63 -26 100 -83 154 -236 78 -219 173 -397 269 -501 76 -81 132 -113 201 -114 48 0 57 3 83 33 22 25 29 42 29 75 0 48 -7 61 -103 171 -94 109 -128 175 -193 372 -67 205 -109 293 -175 367 -104 116 -273 162 -456 122z"
    />
    <path
      d="M1375 1135 c-108 -19 -196 -47 -202 -62 -14 -36 59 -308 116 -436 64 -142 190 -322 249 -355 74 -41 167 -23 192 38 17 41 8 77 -36 146 -98 153 -195 425 -230 642 -4 23 -9 42 -13 41 -3 0 -37 -7 -76 -14z"
    />
    <path
      d="M1029 1031 c-72 -30 -159 -86 -159 -102 0 -5 11 -35 24 -67 13 -31 41 -111 61 -177 51 -162 85 -234 136 -290 54 -58 86 -75 144 -75 38 0 52 5 76 29 43 43 40 89 -11 171 -66 107 -141 330 -165 493 -9 56 -14 57 -106 18z"
    />
  </svg>
)