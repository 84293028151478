import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="-100 -100 1200 1200"
    fill={fill}
    {...rest}
  >
    <path d="M10 10v980h980V10H10zm796 796H684V316H500v490H194V194h612v612z" />
  </svg>
)
