import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 457 457"
    fill={fill}
    {...rest}
  >
    <path
      d="M422 207l-86 86a121 121 0 0 1-190-25l40-39 6-5c3 10 8 19 15 26 24 24 62 24 86 0l86-86a61 61 0 0 0-86-86l-31 31c-24-10-51-12-77-8l65-65a121 121 0 0 1 172 171zM195 348l-31 31a61 61 0 0 1-86-86l86-86c24-24 62-24 86 0 7 7 12 16 15 26l6-4 40-40c-5-9-11-17-18-25-48-47-124-47-172 0l-85 86a121 121 0 0 0 171 172l65-65c-26 3-53 1-77-9z"
    />
  </svg>
)
