import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  rem,
  flex,
  transparentize,
  is,
  isnt,
  minWidth,
  maxHeight,
} from 'styled-tidy'
import debounce from 'lodash.debounce'
import { Link } from 'gatsby'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import linkCircle from 'lib/styles/link-circle'
import Logo from 'components/logos/dw2'
import Pencil from 'components/icons/pencil'
import Github from 'components/icons/github'
import Twitter from 'components/icons/twitter'
import ExternalLink from 'components/external-link'

const { white, bone, tundra, black } = theme.colors

const Container = styled.header`
  ${flex('row', 'center', 'center')}
  ${is('scrolled')`
    box-shadow: 0 0 ${rem(96)} ${transparentize(black, 0.8)};
    ${is('hasSubnav')`
      box-shadow: 0 ${rem(48)} ${rem(96)} ${transparentize(black, 0.8)};
    `}
  `}
  ${isnt('scrolled')`
    box-shadow: 0 ${rem(1)} 0 ${bone};
  `}
  background: ${white};
  color: ${tundra};
  position: sticky;
  top: 0;
  transition: box-shadow 1s ease;
  user-select: none;
  z-index: 8;
`

const Content = styled.div`
  ${flex('row', 'center', 'space-between')}
  ${linkCircle}
  height: ${rem(64)};
  max-width: ${rem(1032)};
  transition: max-width 500ms linear;
  width: 100%;

  ${minWidth(1224)`max-width: ${rem(1224)}`};

  ${media.xsmall`
    height: ${rem(96)};
  `}

  ${maxHeight(600)`
    height: ${rem(64)};
  `}
`

const Branding = styled.menu`
  ${flex('row', 'center', 'center')}
  font-size: ${rem(16)};
  padding: 0 ${rem(16)};
  text-transform: uppercase;
  
  span {
    display: none;
  }

  a {
    height: ${rem(56)};
    width: ${rem(56)};

    svg {
      height: auto;
      width: ${rem(48)};
    }
  }

  ${minWidth(440)`
    span {
      display: flex;
    }
  `}

  ${media.xsmall`
    a {
      height: ${rem(80)};
      width: ${rem(80)};

      svg {
        height: auto;
        width: ${rem(64)};
      }
    }
  `}

  ${maxHeight(600)`
    a {
      height: ${rem(56)};
      width: ${rem(56)};

      svg {
        height: auto;
        width: ${rem(48)};
      }
    }
  `}
`

const Menu = styled.menu`
  ${flex('row', 'center', 'center')}

  a {
    height: ${rem(40)};
    width: ${rem(40)};
  }
`

export default ({ siteTitle, children }) => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const checkScrollPosition = () => setIsScrolled(global.scrollY > 48)
    const handleScroll = debounce(checkScrollPosition, 50)

    checkScrollPosition()
    global.addEventListener('scroll', handleScroll)
    return () => global.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container scrolled={isScrolled} hasSubnav={children ? 1 : 0}>
      <Content>
        <Branding>
          <Link to="/" aria-label="Home">
            <Logo />
          </Link>
          <span>{siteTitle}</span>
        </Branding>
        <Menu>
          <Link aria-label="Blog" to="/blog">
            <Pencil />
          </Link>
          <ExternalLink aria-label="Github" href="https://github.com/dw2">
            <Github />
          </ExternalLink>
          <ExternalLink
            aria-label="Twitter"
            href="https://twitter.com/dougwaltman"
          >
            <Twitter />
          </ExternalLink>
        </Menu>
      </Content>
      {children}
    </Container>
  )
}
