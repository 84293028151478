import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  rotate = 270,
  color = theme.colors.white,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 96 96"
    {...rest}
  >
    <g transform={`rotate(${rotate} 48 48)`}>
      <circle fill={fill} cx={48} cy={48} r={48} />
      <path
        fill={color}
        transform="translate(32 32)"
        d="M21 5h-1v2l8 8H1l-1 1 1 1h27l-8 8v1h1l10-9v-2L21 5z"
      />
    </g>
  </svg>
)
