export default {
  colors: {
    primary: '#EB3461',
    replyBlue: '#0E86FE',
    github: '#9859F0',
    npm: '#FB3E44',
    instagram: '#f77736',
    twitter: '#1DA1F2',
    facebook: '#4167B2',
    weedmaps: '#00CDBE',

    // grayscale
    white: '#FFFFFF',
    ghost: '#F3F3F3',
    bone: '#E6E6E6',
    tundra: '#AFAFAF',
    silver: '#9C9C9C',
    slate: '#606060',
    coal: '#363636',
    black: '#000000',

    // syntax highlighting
    syntax: {
      attrName: '#FA743E',
      attrValue: '#A4B81F',
      background: '#073642',
      boolean: '#E6BA3C',
      className: '#E6BA3C',
      constant: '#53ACED',
      comment: '#888888',
      default: '#BBBBBB',
      function: '#53ACED',
      hex: '#868BF0',
      keyword: '#868BF0',
      number: '#A4B81F',
      operator: '#EB3461',
      parameter: '#FA743E',
      property: '#E6BA3C',
      punctuation: '#46BDB4',
      regex: '#A4B81F',
      selector: '#EB3461',
      string: '#E6E6E6',
      token: '#E6E6E6',
    },
  },
  fonts: {
    primary: 'Karla, sans-serif',
    secondary: 'Heebo, sans-serif',
    mono: "'Fira Mono', monospace",
  },
  breakpoints: ['400px', '640px', '832px', '1024px', '1200px'],
}
