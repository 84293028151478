import { createGlobalStyle } from 'styled-components'
import { rem } from 'styled-tidy'
import theme from 'lib/styles/theme'
import syntax from 'lib/styles/syntax'

const { colors, fonts } = theme
const { white, ghost, bone, coal, black, primary } = colors

export default createGlobalStyle`
  html,
  body,
  input,
  textarea {
    font-family: ${fonts.primary};
  }
  html,
  body {
    height: 100%;
  }
  html{
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    background-color: ${white};
    font-weight: 400;
    color: ${coal};
    margin: 0;
    text-align: center;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
  }

  * {
    box-sizing: border-box;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    &, * {
      ::selection {
        background: ${primary};
        color: ${white};
      }
    }
  }
  div {
    margin: 0;
    padding: 0;
  }

  h1, h1 *, h2, h2 *, h3, h3 *, h4, h4 * {
    color: ${black};
    font-family: ${fonts.secondary};
  }
  h1, h2, h3, h4 {
    line-height: 1;
    font-weight: 900;
  }
  h1 {
    font-size: ${rem(36)};
  }
  h2 {
    font-size: ${rem(30)};
  }
  h3 {
    font-size: ${rem(24)};
  }
  h4 {
    font-size: ${rem(18)};
  }

  img {
    border-style: none;
    -ms-interpolation-mode: bicubic;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
    -webkit-touch-callout: none;
  }
  p, li {
    a {
      border-radius: ${rem(2)};
      color: ${primary};
      text-decoration: underline;
      transition: all 250ms ease;

      :hover,
      :focus {
        background: ${ghost};
        box-shadow: 0 0 0 ${rem(4)} ${ghost};
        outline: none;
        text-decoration: none;
      }
    }
  }

  strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  ol, ul{
    padding: 0;
    padding-inline-start: 0;
    margin-left: ${rem(24)};

    li {
      font-size: inherit;
    }

    ol, ul {
      margin-bottom: 0;
    }
  }
  ul > li {
    list-style: none;
    list-style-position: outside;
  }
  ol > li {
    list-style: decimal;
  }
  dl, dd, dt {
    margin: 0;
  }

  blockquote {
    border-left: ${rem(8)} solid ${bone};
    margin: 0;
    padding: ${rem(8)} ${rem(16)};
  }

  table {
    border: ${rem(1)} solid ${bone};
    border-collapse: collapse;
    border-radius: ${rem(4)};
  }
  thead {
    font-size: ${rem(16)};
    th {
      background: ${ghost};
    }
  }
  tbody {
    font-size: ${rem(14)};
    th {
      border-right: ${rem(1)} solid ${bone};
    }
  }
  th, td {
    border-top: ${rem(1)} solid ${bone};
    padding: ${rem(8)};
    text-align: left;
    vertical-align: top;
  }
  tr:first-child {
    th, td {
      border-top: 0;
    }
  }

  pre,
  code,
  .gatsby-highlight {
    font-family: ${fonts.mono};
  }
  code.language-text {
    background: ${ghost};
    border-radius: ${rem(4)};
    box-shadow: 0 0 0 ${rem(4)} ${ghost};
    margin: 0 ${rem(4)};
    padding: 0 ${rem(2)};
  }

  h1, h2, h3, h4,
  p, ul, ol, dl,
  blockquote, table, pre,
  .gatsby-highlight {
    text-rendering: optimizeLegibility;

    + h1, + h2, + h3, + h4,
    + p, + ul, + ol, + dl,
    + blockquote, + table, + pre,
    + .gatsby-highlight {
      margin-top: 1em;
    }
  }

  ${syntax}
`
