import styled from 'styled-components'
import { rem } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { primary, silver, coal } = theme.colors

export default styled.li`
  padding: ${rem(4)} 0;
  
  a,
  span {
    display: block;
    font-size: ${rem(16)};
    padding: ${rem(6)} 0;
    text-align: center;
  }

  span {
    color: ${silver};
    font-style: italic;

    :after {
      content: '\u21AB';
      font-size: ${rem(18)};
      line-height: 1;
      margin-left: ${rem(8)};
    }
  }

  a {
    border-radius: ${rem(2)};
    color: ${coal};
    text-decoration: none;

    :hover {
      color: ${primary};
    }
  }

  ${media.medium`
    a,
    span {
      font-size: ${rem(14)};
      text-align: left;
      padding: 0;
    }
  `}
`
