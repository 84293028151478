import React, { Fragment, useMemo } from 'react'
import { inject } from 'mobx-react'
import { useStaticQuery, graphql } from 'gatsby'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import styled, { ThemeProvider } from 'styled-components'
import { is, rem, minWidth } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import GlobalStyle from 'lib/styles/global-style'
import Head from 'components/head'
import Header from 'components/header'
import Subnav from 'components/subnav'
import Footer from 'components/footer'

export const Main = styled.main`
  margin: 0 auto;
  padding: ${rem(48)} ${rem(16)};
  max-width: ${rem(1200)};
  overflow-x: hidden;

  ${is('hasSubnav')`
    padding-top: ${rem(64)};
  `}

  ${media.medium`
    min-height: 0;
  `}

  ${minWidth(1232)`
    margin: 0 auto;
    padding: ${rem(48)} 0;

    ${is('hasSubnav')`
      padding-top: ${rem(64)};
    `}
  `}
`

const DefaultLayout = ({ comments: commentsStore, children, meta, subnav }) => {
  const firebase = React.useContext(FirebaseContext)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { title } = data.site.siteMetadata
  const childTypes = React.Children.map(children, child =>
    child && child.type ? child.type.displayName : ''
  )
  const Wrapper = childTypes.includes('default__Main') ? Fragment : Main

  useMemo(() => {
    if (!firebase) return
    commentsStore.setFirestore(firebase.firestore())
  }, [firebase, commentsStore])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {meta && <Head {...meta} />}
      <Header siteTitle={title} hasSubnav={subnav ? 1 : 0}>
        {subnav && <Subnav links={subnav} />}
      </Header>
      <Wrapper>{children}</Wrapper>
      <Footer />
    </ThemeProvider>
  )
}

export default inject('comments')(DefaultLayout)
