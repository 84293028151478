export default [
  {
    name: 'Weedmaps',
    type: 'app',
    path: '/projects/weedmaps',
    urls: {
      web: 'https://weedmaps.com',
    },
    images: [
      'weedmaps.jpg',
      'weedmaps-home.jpg',
      'weedmaps-map.jpg',
      'weedmaps-deliveries.jpg',
      'weedmaps-ordering.jpg',
      'weedmaps-smoke.jpg',
    ],
    title: {
      color: '#00cdbe',
      bg: '#000000',
    },
    tile: {
      color: '#00cdbe',
      bg: 'linear-gradient(135deg, #333333, #000000)',
    },
    description: 'Connecting cannabis consumers and products.',
  },
  {
    name: 'Styled-Tidy',
    type: 'opensource',
    path: '/projects/styled-tidy',
    urls: {
      github: 'https://github.com/dw2/styled-tidy',
      npm: 'https://www.npmjs.com/package/styled-tidy',
    },
    images: ['styled-tidy.jpg'],
    title: {
      color: '#EBF7FF',
      bg: '#0287B5',
    },
    tile: {
      color: '#EBF7FF',
      bg: 'linear-gradient(135deg, #06b9dc, #0770af)',
    },
    description: 'Utilities for writing cleaner styled-components in React.',
  },
  {
    name: 'Evilneon',
    type: 'collaboration',
    path: '/projects/evilneon',
    urls: {
      web: 'https://evilneon.com/',
      instagram: 'https://instagram.com/evil.neon',
      facebook: 'https://www.facebook.com/Evilneon1/',
    },
    images: [
      'evilneon.jpg',
      'evilneon-2.jpg',
      'evilneon-3.jpg',
      'evilneon-4.jpg',
      'evilneon-5.jpg',
    ],
    title: {
      color: '#F1D3B9',
      bg: '#000000',
    },
    tile: {
      color: '#000000',
      bg: 'linear-gradient(90deg, #DC1609, #F5382C)',
    },
    description: 'An artistic voice celebrating the harmony of dark and light.',
  },
  {
    name: 'ChopLines',
    type: 'opensource',
    path: '/projects/chop-lines',
    urls: {
      github: 'https://github.com/dw2/chop-lines',
      npm: 'https://www.npmjs.com/package/chop-lines',
    },
    images: ['chop-lines.jpg'],
    title: {
      color: '#FEF900',
      bg: '#000000',
    },
    tile: {
      color: '#000000',
      bg: 'linear-gradient(135deg, #FEF900, #F7D601)',
    },
    description: 'React component to truncate multiple lines of HTML markup.',
  },
  {
    name: 'Polysize',
    type: 'opensource',
    path: '/projects/polysize',
    urls: {
      github: 'https://github.com/dw2/polysize',
      npm: 'https://www.npmjs.com/package/polysize-node',
    },
    images: ['polysize.jpg'],
    title: {
      color: '#FCEBFF',
      bg: '#A94AB7',
    },
    tile: {
      color: '#FCEBFF',
      bg: 'linear-gradient(135deg, #A73BAF, #C962D9)',
    },
    description: 'JavaScript tool for resizing images using HTML5 Canvas.',
  },
]
