import React from 'react'
import styled from 'styled-components'
import { rem, flex, maxHeight, position } from 'styled-tidy'
import { Link } from 'gatsby'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import scrollToY from 'lib/scroll-to-y'
import stopEvent from 'lib/stop-event'
import pillLink from 'lib/styles/pill-link'

const { white, bone } = theme.colors

const Subnav = styled.div`
  ${flex('row', 'center', 'center')}
  ${position('absolute', '100%', 0, 'auto')}
  background: ${white};
  border: ${rem(1)} solid ${bone};
  border-left: 0;
  border-right: 0;
  height: ${rem(48)};

  menu {
    max-width: ${rem(1032)};
    padding: 0 ${rem(16)};
    text-align: left;
    transition: max-width 500ms linear;
    white-space: nowrap;
    width: 100%;
  }

  a {
    ${pillLink}
  }

  ${media.xsmall`
    top: ${rem(96)};
  `}

  ${maxHeight(599)`
    top: ${rem(64)};
  `}
`

export default ({ links }) => {
  const handleClick = (event, element) => {
    const { innerWidth, innerHeight } = global
    const offset = innerWidth < 400 || innerHeight < 600 ? 128 : 160

    stopEvent(event)
    scrollToY(element.offsetTop - offset)
    event.currentTarget.blur()
  }

  return (
    <Subnav>
      <menu>
        {links.map(({ id, ref, to, name, label }) =>
          typeof id === 'undefined' ? (
            <Link
              key={`subnav-${to}`}
              to={to}
              {...(label ? { 'aria-label': label } : null)}
            >
              {name}
            </Link>
          ) : (
            <a
              key={`subnav-${id}`}
              href={`#${id}`}
              onClick={event => handleClick(event, ref.current)}
            >
              {name}
            </a>
          )
        )}
      </menu>
    </Subnav>
  )
}
