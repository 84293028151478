import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="20 20 56 56"
    fill={fill}
    {...rest}
  >
    <path
      d="M48 21a27 27 0 1 0 0 54 27 27 0 0 0 0-54zm7 27h-5l1 17h-8V48h-3v-6h3v-4c0-3 2-7 7-7h6v6h-4l-2 1v4h6l-1 6z"
    />
  </svg>
)
