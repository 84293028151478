export default (toY, callback) => {
  let lastY
  const scroll = () => {
    const { scrollY: currentY } = window
    const adjustY = Math.ceil((toY - currentY) * 0.15)
    const nextY = Math.abs(adjustY) > 1 ? currentY + adjustY : toY

    window.scrollTo(0, nextY)

    if (nextY !== toY && currentY !== lastY) {
      lastY = currentY
      window.requestAnimationFrame(scroll)
    } else {
      window.scrollTo(0, toY)
      if (callback) callback()
    }
  }
  scroll()
}
