import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export default ({ description, lang, meta, title }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const { siteMetadata } = data.site
  const metaTitle = title || siteMetadata.title
  const metaDescription = description || siteMetadata.description
  const titleTemplate = title
    ? `%s | ${siteMetadata.title}`
    : siteMetadata.title
  const fonts = 'Karla:400,400i,700|Heebo:900|Fira+Mono'

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...(meta ? meta : []),
      ]}
    >
      <link
        href={`https://fonts.googleapis.com/css?family=${fonts}&display=swap`}
        rel="stylesheet"
      />
    </Helmet>
  )
}
