export default (element, toX, callback) => {
  let lastX
  const scroll = () => {
    const { scrollLeft: currentX, scrollTop } = element
    const adjustX = Math.ceil((toX - currentX) * 0.15)
    const nextX = Math.abs(adjustX) >= 1 ? currentX + adjustX : toX

    element.scrollTo(nextX, scrollTop)

    if (nextX !== toX && currentX !== lastX) {
      lastX = currentX
      global.requestAnimationFrame(scroll)
    } else {
      element.scrollTo(toX, scrollTop)
      if (callback) callback()
    }
  }
  scroll()
}
