import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { rem, value, position, flex } from 'styled-tidy'
import debounce from 'lodash.debounce'
import scrollElementToX from 'lib/scroll-element-to-x'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import { Main } from 'layouts/default'
import Image from 'components/image'
import Arrow from 'components/icons/arrow'

const { white, ghost, black } = theme.colors
const parallaxPercent = 15

const PrevNext = styled.a`
  display: none;

  ${media.small`
    ${flex('row', 'center', 'center')}
    background: ${white};
    border: ${rem(2)} solid ${white};
    border-radius: ${rem(64)};
    height: ${rem(64)};
    padding: ${rem(12)};
    position: absolute;
    top: 50%;
    transition: background 250ms ease, opacity 500ms ease;
    width: ${rem(64)};
    z-index: 5;
  `}
`

const Prev = styled(PrevNext)`
  ${media.small`
    justify-content: flex-end;
    left: 0;
    transform: translate(-50%, -50%);
  `}
  ${media.xlarge`
    justify-content: center;
  `}
`

const Next = styled(PrevNext)`
  ${media.small`
    justify-content: flex-start;
    right: 0;
    transform: translate(50%, -50%);
  `}
  ${media.xlarge`
    justify-content: center;
  `}
`

export const HeaderTitleWrap = styled.div`
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  user-select: none;

  :before {
    content: '';
    display: block;
    padding-bottom: 60%;
  }

  + ${Main} {
    padding-top: 0;
  }

  .gatsby-image-wrapper {
    ${position('absolute !important', `-${parallaxPercent * 2}%`, 0)}
    display: flex;
    overflow: hidden;

    img {
      @keyframes headerTitleImageZoomOut {
        0% {
          transform: scale(1.3);
        }
        100% {
          transform: none;
        }
      }
      animation: headerTitleImageZoomOut 500ms forwards ease;
    }
  }

  h1 {
    @keyframes headerTitleSlideIn {
      0% {
        transform: translateX(-100%);
      }
      70% {
        transform: translateX(${rem(12)});
      }
      100% {
        transform: none;
      }
    }
    ${position('absolute', 'auto', 'auto', 24, -24)}
    animation: headerTitleSlideIn 500ms forwards linear;
    background: ${value('bg')};
    border-radius: 0 ${rem(4)} ${rem(4)} 0;
    color: ${value('color')};
    font-size: 8vw;
    max-width: calc(100% - ${rem(32)});
    padding: ${rem(8)} ${rem(24)} ${rem(8)} ${rem(40)};
    text-align: left;
  }

  ${PrevNext} {
    :hover,
    :focus {
      background: ${value('color')};

      svg {
        fill: ${value('bg')};
      }
    }
  }

  ${media.xsmall`
    :before {
      padding-bottom: 50%;
    }
    h1 {
      font-size: ${rem(32)};
    }
  `}

  ${media.small`
    :before {
      padding-bottom: 40%;
    }
  `}

  ${media.medium`
    :before {
      padding-bottom: 30%;
    }
    h1 {
      font-size: ${rem(36)};
    }
  `}

  ${media.large`
    h1 {
      padding-left: calc(50% - ${rem(472)});
    }
  `}

  ${media.xlarge`
    max-width: ${rem(1000)};
    overflow: visible;

    h1 {
      padding-left: ${rem(76)};
    }
  `}
`

const Clip = styled.div`
  ${position('absolute', 0)}
  overflow: hidden;
  pointer-events: none;

  ${media.xlarge`
    border-radius: 0 0 ${rem(4)} ${rem(4)};
  `}
`

const Track = styled.div`
  ${position('absolute', 0)}
  background: ${ghost};
  overflow: hidden;

  ul {
    ${flex('row', 'center', 'flex-start')}
    flex-wrap: nowrap;
    height: calc(100% + ${rem(64)});
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  li {
    display: flex;
    flex-shrink: 0;
    height: calc(100% - ${rem(64)});
    position: relative;
    width: 100%;
  }

  ${media.xlarge`
    border-radius: 0 0 ${rem(4)} ${rem(4)};
  `}
`

export default ({ bg = white, color = black, title, images }) => {
  const headerRef = useRef(null)
  const trackRef = useRef(null)
  const listRef = useRef(null)
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  let isAnimating = false

  const togglePrevNext = () => {
    const list = listRef.current
    const prev = prevRef.current
    const next = nextRef.current

    if (global.innerWidth < 640 || !list || !prev || !next) return

    const itemWidth = list.firstChild.offsetWidth
    const itemsWidth = itemWidth ? itemWidth * list.childNodes.length : 0
    const prevEnabled = list.scrollLeft >= itemWidth
    const nextEnabled = list.scrollLeft < itemsWidth - list.offsetWidth

    prev.style.opacity = prevEnabled ? 1 : 0
    next.style.opacity = nextEnabled ? 1 : 0
    prev.style.pointerEvents = prevEnabled ? 'initial' : 'none'
    next.style.pointerEvents = nextEnabled ? 'initial' : 'none'
  }

  const snapToNearest = offset => {
    const track = trackRef.current
    const list = listRef.current

    if (isAnimating || !track || !list) return
    global.requestAnimationFrame(() => {
      const trackWidth = track.offsetWidth
      const snap = Math.round(list.scrollLeft / trackWidth) + offset

      isAnimating = true
      scrollElementToX(list, snap * trackWidth, () => {
        isAnimating = false
        togglePrevNext()
      })
    })
  }

  const handlePrevClick = () => snapToNearest(-1)
  const handleNextClick = () => snapToNearest(1)
  const handleReposition = debounce(() => snapToNearest(0), 150)

  useEffect(() => {
    if (!global.window || !headerRef.current) return

    const handleScroll = () => {
      const { scrollY } = global
      const { offsetHeight } = headerRef.current
      const imgWrappers = headerRef.current.getElementsByClassName(
        'gatsby-image-wrapper'
      )
      const slideY =
        (Math.min(scrollY, offsetHeight) / offsetHeight) * parallaxPercent

      Array.prototype.forEach.call(imgWrappers, wrapper => {
        wrapper.style.transform = slideY ? `translateY(-${slideY}%)` : 'none'
      })
    }

    global.addEventListener('scroll', handleScroll)
    return () => global.removeEventListener('scroll', handleScroll)
  }, [headerRef])

  useEffect(() => {
    const list = listRef.current
    if (!list) return
    list.addEventListener('scroll', handleReposition)
    global.addEventListener('resize', handleReposition)
    togglePrevNext()
    return () => {
      list.removeEventListener('scroll', handleReposition)
      global.removeEventListener('resize', handleReposition)
    }
  }, [listRef, handleReposition])

  return (
    <HeaderTitleWrap ref={headerRef} bg={bg} color={color}>
      {images.length > 1 ? (
        <>
          <Prev ref={prevRef} onClick={handlePrevClick}>
            <Arrow rotate={180} size={16} fill={black} />
          </Prev>
          <Track ref={trackRef}>
            <ul ref={listRef}>
              {images.map(image => (
                <li key={image}>
                  <Image file={image} />
                </li>
              ))}
            </ul>
          </Track>
          <Next ref={nextRef} onClick={handleNextClick}>
            <Arrow rotate={0} size={16} fill={black} />
          </Next>
          <Clip>
            <h1>{title}</h1>
          </Clip>
        </>
      ) : (
        <Clip>
          <Image file={images[0]} />
          <h1>{title}</h1>
        </Clip>
      )}
    </HeaderTitleWrap>
  )
}
