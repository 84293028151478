import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 512 512"
    fill={fill}
    {...rest}
  >
    <path
      d="M256 49c67 0 75 1 102 2 24 1 38 5 47 9a78 78 0 0 1 29 18 78 78 0 0 1 18 29c4 9 8 23 9 47 1 27 2 35 2 102l-2 102c-1 24-5 38-9 47a83 83 0 0 1-47 47c-9 4-23 8-47 9-27 1-35 2-102 2l-102-2c-24-1-38-5-47-9a78 78 0 0 1-29-18 78 78 0 0 1-18-29c-4-9-8-23-9-47-1-27-2-35-2-102l2-102c1-24 5-38 9-47a78 78 0 0 1 18-29 78 78 0 0 1 29-18c9-4 23-8 47-9 27-1 35-2 102-2m0-45c-68 0-77 0-104 2-27 1-45 5-61 11a123 123 0 0 0-45 29 123 123 0 0 0-29 45c-6 16-10 34-11 61-2 27-2 36-2 104l2 104c1 27 5 45 11 61a123 123 0 0 0 29 45 123 123 0 0 0 45 29c16 6 34 10 61 11a1796 1796 0 0 0 208 0c27-1 45-5 61-11a129 129 0 0 0 74-74c6-16 10-34 11-61 2-27 2-36 2-104l-2-104c-1-27-5-45-11-61a123 123 0 0 0-29-45 123 123 0 0 0-45-29c-16-6-34-10-61-11-27-2-36-2-104-2z"
    />
    <path
      d="M256 127a129 129 0 1 0 129 129 129 129 0 0 0-129-129zm0 213a84 84 0 1 1 84-84 84 84 0 0 1-84 84z"
    />
    <circle cx="390" cy="122" r="30" />
  </svg>
)
