import { css } from 'styled-components'
import { rem, flex, position } from 'styled-tidy'
import theme from 'lib/styles/theme'

const {
  primary,
  github,
  npm,
  instagram,
  twitter,
  facebook,
  bone,
} = theme.colors

export default css`
  a {
    ${flex('row', 'center', 'center')}
    height: ${rem(40)};
    margin-right: ${rem(16)};
    outline: none;
    position: relative;
    width: ${rem(40)};

    :after {
      ${position('absolute', '20%')}
      border-radius: ${rem(48)};
      border: ${rem(2)} solid ${bone};
      content: '';
      opacity: 0;
      transition: all 250ms ease;
    }

    :focus:after {
      bottom: ${rem(-2)};
      left: ${rem(-2)};
      opacity: 1;
      right: ${rem(-2)};
      top: ${rem(-2)};
    }

    :active:after {
      display: none;
    }

    svg {
      transition: fill 250ms ease;
    }

    :hover,
    :focus {
      svg {
        fill: ${primary};
      }
    }

    &[aria-label="Scroll Up"] {
      circle {
        transition: fill 250ms ease;
      }
      :hover,
      :focus {
        svg {
          fill: none;
        }
        circle {
          fill: ${primary};
        }
      }
    }

    &[aria-label="Github"] {
      :hover,
      :focus {
        svg {
          fill: ${github};
        }
      }
    }

    &[aria-label="NPM"] {
      :hover,
      :focus {
        svg {
          fill: ${npm};
        }
      }
    }

    &[aria-label="Instagram"] {
      :hover,
      :focus {
        svg {
          fill: ${instagram};
        }
      }
    }

    &[aria-label="Twitter"] {
      :hover,
      :focus {
        svg {
          fill: ${twitter};
        }
      }
    }

    &[aria-label="Facebook"] {
      :hover,
      :focus {
        svg {
          fill: ${facebook};
        }
      }
    }
  }
`
