import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { rem, flex, is, minWidth, position } from 'styled-tidy'
import debounce from 'lodash.debounce'
import { Link } from 'gatsby'
import stopEvent from 'lib/stop-event'
import linkCircle from 'lib/styles/link-circle'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import scrollToY from 'lib/scroll-to-y'
import ArrowCircle from 'components/icons/arrow-circle'
import ExternalLink from 'components/external-link'

const { white, bone, silver } = theme.colors

const Container = styled.footer`
  ${flex('row', 'center', 'center')}
  border-top: ${rem(1)} solid ${bone};
  height: ${rem(80)};
  user-select: none;

  > div {
    ${flex('row', 'center', 'space-between')}
    padding: 0 ${rem(20)} 0 ${rem(2)};
    position: relative;
    text-align: right;
    transition: max-width 500ms linear;
    width: 100%;
  }

  p a {
    color: ${silver};
    font-size: ${rem(14)};
    text-decoration: none;
    text-transform: uppercase;

    + a {
      margin-left: ${rem(12)};
    }
  }

  ${media.xsmall`
    height: ${rem(96)};

    p a + a {
      margin-left: ${rem(16)};
    }
  `}

  ${media.medium`
    > div {
      justify-content: flex-end;
      max-width: ${rem(1032)};
    }
  `}

  ${minWidth(1224)`
    > div {
      max-width: ${rem(1168)};
      padding-right: 0;
    }
  `}
`

const Links = styled.p`
  ${flex('row', 'center', 'space-between')}
  width: 100%;
`

const Netlify = styled(ExternalLink)`
  margin-left: 0;
  position: relative;

  :after {
    ${position('absolute', 0)}
    border-radius: ${rem(2)};
    box-shadow: inset 0 0 0 ${rem(4)} ${white};
    content: '';
  }

  :hover,
  :focus {
    background: none;
    box-shadow: none;
  }
`

const Top = styled.div`
  ${linkCircle};
  opacity: 0;
  overflow: hidden;
  width: 0;
  transition: all 250ms ease;

  ${is('show')`
    opacity: 1;
    width: ${rem(56)};

    a {
      height: ${rem(48)};
      position: absolute;
      transform: translate(${rem(12)}, -50%);
      width: ${rem(48)};

      svg {
        height: ${rem(44)};
        width: ${rem(44)};
      }
    }

    ${media.xsmall`
      width: ${rem(80)};
    
      a {
        height: ${rem(64)};
        width: ${rem(64)};

        svg {
          height: ${rem(60)};
          width: ${rem(60)};
        }
      }
    `}

    ${media.medium`
      width: ${rem(84)};
    `}

    ${minWidth(1224)`
      width: ${rem(72)};

      a {
        position: absolute;
      }
    `}
  `}
`

export default () => {
  const [showTop, setShowTop] = useState(false)
  const scrollUp = event => {
    stopEvent(event)
    scrollToY(0)
  }

  useEffect(() => {
    const checkScrollPosition = () => setShowTop(global.scrollY > 120)
    const handleScroll = debounce(checkScrollPosition, 50)

    checkScrollPosition()
    global.addEventListener('scroll', handleScroll)
    return () => global.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Container>
      <div>
        <Links>
          <Netlify href="https://www.netlify.com">
            <img
              src="https://www.netlify.com/img/global/badges/netlify-light.svg"
              alt=""
            />
          </Netlify>
          <span>
            <Link to="/">Projects</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/about">About</Link>
          </span>
        </Links>
        <Top show={showTop}>
          <a
            aria-label="Scroll Up"
            role="button"
            href="#"
            tabIndex={0}
            onClick={scrollUp}
            onKeyPress={scrollUp}
          >
            <ArrowCircle size={60} />
          </a>
        </Top>
      </div>
    </Container>
  )
}
