import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default ({ file, alt = '' }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 832) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges } = data.images
      const image = edges.find(n => n.node.relativePath.includes(file))

      if (!image) return null
      return <Img alt={alt} fluid={image.node.childImageSharp.fluid} />
    }}
  />
)
