import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 578 578"
    fill={fill}
    {...rest}
  >
    <path
      d="M533 160c1-26-5-53-10-79-1-9-5-18-8-27-2-6-10-10-16-9l-12 3c-39 13-76 32-111 54-5 3-11 4-17 3l-41-5c-33-1-67 0-100 6-5 1-11 0-15-3-38-24-77-46-122-57-7-2-14-1-15 1l-3 6c-5 16-10 33-13 51-4 20-7 42-5 62 1 13-3 21-10 30-24 31-34 66-35 104-1 33 4 65 14 97 15 48 45 84 90 108 36 19 76 27 117 28h111c37 1 73-3 109-14 35-12 66-30 90-60 34-42 45-92 47-145 2-40-6-78-30-111a65 65 0 0 1-15-43zm-71 310c-16 13-35 20-55 25-39 9-79 11-119 10-39 1-78-1-116-10-28-7-54-18-72-42-29-39-36-112 11-152 12-11 26-17 41-18l53-3 133 3c20 0 40-5 60-4 18 2 38 5 54 12 28 12 42 37 47 66 7 43-2 83-37 113z"
    />
    <ellipse cx="390" cy="386" rx="42" ry="56"/>
    <ellipse cx="189" cy="386" rx="42" ry="56"/>
  </svg>
)
