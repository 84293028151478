import { css } from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { primary, white, ghost, black } = theme.colors

export default css`
  ${flex('row', 'center', 'center')}
  background: ${ghost};
  border-radius: ${rem(30)};
  color: ${black};
  display: inline-flex;
  height: ${rem(24)};
  font-size: ${rem(14)};
  outline: none;
  margin-right: ${rem(4)};
  padding: 0 ${rem(8)};
  transition: all 250ms ease;

  svg {
    transition: fill 250ms ease;
  }

  :hover {
    background: ${primary};
    color: ${white};

    svg {
      fill: ${white};
    }
  }

  :focus {
    background: ${black};
    color: ${white};

    svg {
      fill: ${black};
    }
  }
`
