import React from 'react'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import linkCircle from 'lib/styles/link-circle'
import projects from 'lib/projects'
import Layout, { Main } from 'layouts/default'
import Chain from 'components/icons/chain'
import Github from 'components/icons/github'
import NPM from 'components/icons/npm'
import Instagram from 'components/icons/instagram'
import Facebook from 'components/icons/facebook'
import HeaderTitle from 'components/header-title'
import ExternalLink from 'components/external-link'
import ProjectMenu from 'components/project-menu'

const { bone } = theme.colors

const Article = styled.article`
  display: grid;
  grid-gap: ${rem(24)};
  grid-template: 'menu' 'intro' 'main' 'projects';
  margin: 0 auto;
  max-width: ${rem(1000)};
  width: 100%;

  ${media.small`
    grid-template:
      'menu intro projects'
      'main main main';
    grid-template-columns: ${rem(56)} auto ${rem(128)};
    margin-top: ${rem(24)};
  `}
`

const Menu = styled.menu`
  ${flex('row', 'center', 'flex-start')}
  border-bottom: ${rem(1)} solid ${bone};
  flex-shrink: 0;
  grid-area: menu;
  padding: ${rem(8)} 0;

  ${linkCircle}

  ${media.small`
    align-items: center;
    border-bottom: 0;
    border-right: ${rem(1)} solid ${bone};
    flex-direction: column;
    justify-content: flex-start;

    a + a {
      margin-top: ${rem(16)};
    }
  `}
`

const IntroSection = styled.section`
  grid-area: intro;
  text-align: left;
  width: 100%;

  ${media.small`
    padding-right: ${rem(24)};
  `}
`

const Projects = styled.aside`
  grid-area: projects;
  padding-right: ${rem(8)};
  text-align: left;
  user-select: none;

  h3 {
    border-bottom: ${rem(1)} solid ${bone};
    font-size: ${rem(32)};
    margin-bottom: ${rem(16)};
    padding-bottom: ${rem(8)};
    text-align: center;
  }

  ${media.small`
    h3 {
      font-size: ${rem(24)};
      text-align: left;
    }

    a,
    span {
      font-size: ${rem(14)};
      text-align: left;
      padding: 0;
    }
  `}
`

const MainSection = styled.section`
  grid-area: main;
  max-width: 100%;
  overflow: hidden;
  width: 100%;

  h2 {
    border-bottom: ${rem(1)} solid ${bone};
    margin-bottom: ${rem(24)};
    padding-bottom: ${rem(16)};
  }

  p,
  ul {
    text-align: left;
  }

  ${media.small`
    margin: ${rem(24)} auto 0;

    h2, h3, h4 {
      text-align: left;
    }
  `}
`

export default ({ name, subnav, children }) => {
  const project = projects.find(p => p.name === name)
  const { title, description, images, path, urls } = project

  return (
    <Layout meta={{ title: name, description }} subnav={subnav}>
      <HeaderTitle
        bg={title.bg}
        color={title.color}
        title={name}
        images={images}
      />
      <Main>
        <Article>
          <Menu>
            {urls.web && (
              <ExternalLink aria-label="Website" href={urls.web}>
                <Chain />
              </ExternalLink>
            )}
            {urls.github && (
              <ExternalLink aria-label="Github" href={urls.github}>
                <Github />
              </ExternalLink>
            )}
            {urls.npm && (
              <ExternalLink aria-label="NPM" href={urls.npm}>
                <NPM />
              </ExternalLink>
            )}
            {urls.instagram && (
              <ExternalLink aria-label="Instagram" href={urls.instagram}>
                <Instagram />
              </ExternalLink>
            )}
            {urls.facebook && (
              <ExternalLink aria-label="Facebook" href={urls.facebook}>
                <Facebook />
              </ExternalLink>
            )}
          </Menu>
          <IntroSection>{children[0]}</IntroSection>
          <Projects>
            <h3>Projects</h3>
            <ProjectMenu currentPath={path} />
          </Projects>
          <MainSection>{children[1]}</MainSection>
        </Article>
      </Main>
    </Layout>
  )
}
