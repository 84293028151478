import { minWidth } from 'styled-tidy'
import theme from 'lib/styles/theme'

const media = {}
const breakpointNames = ['xsmall', 'small', 'medium', 'large', 'xlarge']

theme.breakpoints.forEach((breakpoint, n) => {
  const pixels = parseInt(breakpoint.replace(/[^\d]/g, ''), 10)

  media[breakpointNames[n]] = minWidth(pixels)
})

export default media
